import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { ImageDataValue } from '@/types';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { Field, Form } from 'react-final-form';
import { UiButton } from '@/ui/components/UiButton';
import {
  composeValidators,
  emailValidate,
  nameValidate,
  requiredValidate,
} from '@/helpers/fieldValidate';
import { useMutation } from 'graphql-hooks';
import { FieldSelect } from '@/components/FieldSelect';
import { TextField } from '@/components/TextField';
import { Container } from '@/components/Container';
import { COUNTRY_LIST } from '@/constants';
import { TextAreaField } from '@/components/TextAreaField';
import { DatePickerField } from '@/components/DatePickerField';
import { InputTimeField } from '@/components/InputTimeField';
import dayjs from 'dayjs';
import { SuccessModal } from '@/components/SuccessModal';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import { FormApi } from 'final-form';
import { PhoneField } from '@/components/PhoneField';

interface ContactFormProps {
  sectionData: {
    title: string;
    buttonText: string;
    image: ImageDataValue;
  };
  titleFont?: string;
}

const CREATE_BUSINESS_USER_MUTATION = `mutation CreateBusinessUser($name: String!, $date: String!, $time: String!, 
    $email: String!, 
    $phone: String!, 
    $country: String!
    $company: String!,
    $position: String!,
    $purpose: String!
  ) {
    createContactBusinessUser(input: {
      data: {
      name: $name,
      date: $date,
      time: $time,
      email: $email,
      phone: $phone,
      country: $country,
      company: $company,
      position: $position,
      purpose: $purpose
    }
    }) {
      contactBusinessUser {
        id
        name
        date
        time
        email
        phone
        country
        company
        position
        purpose
      }
    }
}`;

interface FormValues {
  name: string;
  country: {
    label: string;
    value: string;
  };
  date: string;
  time: string;
  email: string;
  phone: string;
  company: string;
  position: string;
  purpose: string;
}

const defaultFormValues = {
  date: '',
  time: '',
  name: '',
  email: '',
  phone: '',
  country: '',
  company: '',
  position: '',
  purpose: '',
};

const ContactForm: FC<ContactFormProps> = ({
  sectionData,
  titleFont = TYPOGRAPHY.title1SemiBold64,
}) => {
  const locale = useContext(LocaleContext);
  const [createUser] = useMutation(CREATE_BUSINESS_USER_MUTATION);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['contactUs', 'inputPlaceholders', 'buttons']),
    [locale],
  );

  const handleSubmit = async (values: FormValues, form: FormApi) => {
    const { data, error } = await createUser({
      variables: {
        ...values,
        country: values.country.label,
        date: `${dayjs(values.date).format('DD.MM.YYYY')}`,
      },
    });

    if (data) {
      setIsOpenModal(true);
      Object.keys(values).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });
    }
  };

  return (
    <Root>
      <SuccessModal
        title={dataLocale.contactUs.successModalTitle}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <StyledContainer>
        <Content>
          <Title $font={titleFont}>{sectionData.title}</Title>
          <FormWrap>
            <Form
              initialValues={defaultFormValues}
              onSubmit={(values: FormValues, form: FormApi) =>
                handleSubmit(values, form)
              }
              render={({ handleSubmit, values, submitting, invalid }) => (
                <form>
                  <InputsWrapper>
                    <Field
                      name="date"
                      render={(props) => {
                        return <DatePickerField {...props} />;
                      }}
                    />
                    <Field
                      name="time"
                      render={(props) => {
                        return <InputTimeField {...props} />;
                      }}
                    />
                    <FullField
                      name="name"
                      type="text"
                      placeholder={dataLocale.inputPlaceholders.fullName}
                      validate={composeValidators(
                        requiredValidate,
                        nameValidate,
                      )}
                      component={TextField}
                    />
                    <FullField
                      name="email"
                      type="email"
                      placeholder={dataLocale.inputPlaceholders.email}
                      validate={composeValidators(
                        requiredValidate,
                        emailValidate,
                      )}
                      component={TextField}
                    />
                    <FullField
                      name="phone"
                      placeholder={dataLocale.inputPlaceholders.phoneNumber}
                      component={PhoneField}
                    />
                    <FullField
                      name="country"
                      placeholder={dataLocale.inputPlaceholders.country}
                      component={FieldSelect}
                      validate={composeValidators(requiredValidate)}
                      options={COUNTRY_LIST}
                    />
                    <FullField
                      name="company"
                      type="text"
                      placeholder={dataLocale.inputPlaceholders.companyName}
                      validate={requiredValidate}
                      component={TextField}
                    />
                    <FullField
                      name="position"
                      type="text"
                      placeholder={dataLocale.inputPlaceholders.position}
                      validate={requiredValidate}
                      component={TextField}
                    />
                    <FullField
                      name="purpose"
                      placeholder={dataLocale.inputPlaceholders.purpose}
                      validate={requiredValidate}
                      component={TextAreaField}
                    />
                    <ReCaptchaWrapper>
                      <ReCAPTCHA
                        sitekey="6LfbowoeAAAAANHDc0iUfox3jP4WG14nrO7Pk6Ua"
                        onChange={(e: string) => setRecaptchaValue(e)}
                      />
                    </ReCaptchaWrapper>
                  </InputsWrapper>
                  <StyledUiButton
                    text={dataLocale.buttons.callMeBack}
                    onClick={handleSubmit}
                    customTheme="primarySmall"
                    isLoading={submitting}
                    disabled={submitting || invalid || !recaptchaValue}
                  />
                </form>
              )}
            />
          </FormWrap>
        </Content>
        <ImageWrap
          style={{
            backgroundImage: `url(${sectionData.image?.localFile.publicURL})`,
          }}
        ></ImageWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section``;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 90px;
  padding-bottom: 200px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;

const Content = styled.div`
  max-width: 615px;
  margin-right: 15px;
  @media (max-width: 1024px) {
    max-width: none;
    margin-right: 0;
    flex-grow: 1;
  }
`;

const Title = styled.h2<{ $font: string }>`
  ${({ $font }) => $font}
  color: ${COLORS.black2};
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2SemiBold48};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
`;

const FormWrap = styled.div`
  max-width: 392px;
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 24px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const FullField = styled(Field)`
  grid-column: 1/-1;
`;

const StyledUiButton = styled(UiButton)`
  width: 100%;
  grid-column: 1/-1;
`;

const ImageWrap = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 608px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${COLORS.lightPurple};
  border-radius: 40px;
  ::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 117.1%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ReCaptchaWrapper = styled.div`
  grid-column: 1/-1;
`;

export default ContactForm;
