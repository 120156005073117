import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Seo } from '@/components/Seo';
import { MainHeading } from '@/components/MainHeading';
import { Hero } from '@/pageComponents/BusinessSolution/Hero';
import { Stages } from '@/pageComponents/BusinessSolution/Stages';
import { Solutions } from '@/pageComponents/BusinessSolution/Solutions';
import { ContactForm } from '@/pageComponents/BusinessSolution/ContactForm';

const BusinessSolution = ({ pageContext }) => {
  const {
    locale,
    seo,
    heroBusinessSolution,
    stages,
    contactUsBusinessPage,
    solutionsTitle,
    usefulLinks,
  } = pageContext.data;

  return (
    <MainLayout locale={locale}>
      <Seo data={seo} />
      <MainHeading text={seo.mainHeading} />

      <Hero data={heroBusinessSolution} />
      <Stages stages={stages} />
      <Solutions title={solutionsTitle} links={usefulLinks} />
      <ContactForm sectionData={contactUsBusinessPage} />
    </MainLayout>
  );
};

export default BusinessSolution;
