import React, { FC } from 'react';
import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { BUTTONS_THEME, COLORS, TYPOGRAPHY } from '@/constants';
import { useBusinessSolutionDetails } from '@/hooks/useBusinessSolutionDetails';
import styled from 'styled-components';
import { DetailCard } from './components/DetailCard';
import { UiLink } from '@/ui/components/UiLink';
import { convertToSlug } from '@/helpers/convertToSlug';
import { Link as ScrollLink } from 'react-scroll';

interface UsefulLinkData {
  id: number;
  link: string;
  text: string;
}

interface SolutionsProps {
  title: string;
  links: UsefulLinkData[];
}

const Solutions: FC<SolutionsProps> = ({ title, links }) => {
  const solutions = useBusinessSolutionDetails();

  return (
    <section>
      <StyledContainer>
        <Title>{title}</Title>
        <MiniDetailCards>
          {solutions.map((item) => {
            const { miniDetailCard } = item;
            return (
              <MiniDetailCard key={item.id}>
                <MiniDetailCardImageWrap>
                  <Image
                    localFile={miniDetailCard.image?.localFile}
                    alt={miniDetailCard.title}
                  />
                </MiniDetailCardImageWrap>
                <MiniDetailCardTitle>
                  {miniDetailCard.title}
                </MiniDetailCardTitle>
                <MiniDetailCardDescription>
                  {miniDetailCard.description}
                </MiniDetailCardDescription>
                <MiniDetailCardAnchor
                  to={convertToSlug(item.title)}
                  smooth={true}
                >
                  {miniDetailCard.buttonText}
                </MiniDetailCardAnchor>
              </MiniDetailCard>
            );
          })}
        </MiniDetailCards>

        <DetailCards>
          {solutions.map((item) => (
            <StyledDetailCard
              id={convertToSlug(item.title)}
              key={item.id}
              title={item.title}
              subtitle={item.subtitle}
              data={item.detailCard}
              products={item.products}
            />
          ))}
        </DetailCards>

        <LinksWrapper>
          {links.map((item) => (
            <StyledUiLink
              key={item.id}
              text={item.text}
              link={item.link}
              customTheme="secondary"
            />
          ))}
        </LinksWrapper>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 80px;
  text-align: center;
  @media (max-width: 650px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    color: ${COLORS.black2};
    margin-bottom: 32px;
    text-align: left;
  }
`;
const MiniDetailCards = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 190px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 100px;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const MiniDetailCard = styled.li`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background-color: ${COLORS.white};
  box-shadow: 0px 5px 26px rgba(202, 202, 219, 0.33);
  @media (max-width: 600px) {
    margin-bottom: 32px;
    min-height: 587px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const MiniDetailCardImageWrap = styled.div`
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 40px;
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

const MiniDetailCardTitle = styled.p`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black2};
  margin-bottom: 20px;
`;

const MiniDetailCardDescription = styled.p`
  ${TYPOGRAPHY.body2Light16};
  color: ${COLORS.black2};
  margin-bottom: 40px;
`;

const MiniDetailCardAnchor = styled(ScrollLink)`
  ${BUTTONS_THEME['primary']};
  cursor: pointer;
  margin-top: auto;
`;

const DetailCards = styled.div`
  margin-bottom: 60px;
  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
`;

const StyledDetailCard = styled(DetailCard)`
  margin-bottom: 280px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1200px) {
    margin-bottom: 100px;
  }
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  max-width: 700px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    gap: 20px;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    gap: 0;
  }
`;

const StyledUiLink = styled(UiLink)`
  @media (max-width: 650px) {
    margin-right: 0;
    margin-bottom: 40px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default Solutions;
