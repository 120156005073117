import { COLORS } from '@/constants';
import React, { FC, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { TextArea } from '../TextArea';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: boolean | string;
  isSuccess?: boolean;
}

const StyledTextArea: FC<InputProps> = ({
  className,
  error = false,
  isSuccess = false,
  ...props
}) => {
  const errorClass = error ? 'error' : '';
  const successClass = isSuccess ? 'success' : '';

  return (
    <Root className={`${className} ${successClass}`}>
      <TextArea className={`${errorClass}`} {...props} />

      {error && <ErrorText>{error}</ErrorText>}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  &.success {
    ::before {
      content: '';
      position: absolute;
      right: 21px;
      top: 17px;
      width: 6px;
      height: 14px;
      border: solid ${COLORS.green};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

const ErrorText = styled.span`
  margin-top: 4px;
  transition: transform 0.3s ease;
  font-size: 10px;
  line-height: 14px;
  color: ${COLORS.red};
`;

export default StyledTextArea;
