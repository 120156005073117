import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import Lottie from 'react-lottie';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

interface HeroProps {
  data: {
    title: string;
    description: string;
    image: ImageDataValue;
  };
}

const Hero: FC<HeroProps> = ({ data }) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: undefined,
    path: data.image?.localFile.publicURL,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Root>
      <StyledContainer>
        <AnimationWrap>
          <Lottie options={animationOptions} />
        </AnimationWrap>
        <ContentWrap>
          <Title>
            <ReactMarkdown>{data.title}</ReactMarkdown>
          </Title>
          <Description>{data.description}</Description>
        </ContentWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray2};
  overflow: hidden;
  margin-top: -86px;
  height: 100vh;

  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 500px) {
    margin-top: -117px;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 225px;
  padding-bottom: 230px;
  height: 100%;
  max-width: none;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 500px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    padding-top: 140px;
    padding-bottom: 300px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AnimationWrap = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;

  @media (max-width: 1300px) {
    bottom: -6%;
    width: 1440px;
  }
  @media (max-width: 1024px) {
    width: 1200px;
    bottom: 0;
  }
  @media (max-width: 768px) {
    width: 768px;
  }
`;

const ContentWrap = styled.div`
  /* max-width: 655px; */
  max-width: 45.48vw;
  position: relative;
  @media (max-width: 1300px) {
    max-width: 800px;
  }
  @media (max-width: 1024px) {
    max-width: none;
  }
`;

const Title = styled.div`
  /* margin-bottom: 40px; */
  margin-bottom: 2.77vw;
  h1,
  h2,
  p {
    font-size: 4.44vw;
    line-height: 5.2vw;
    color: ${COLORS.black1};
    strong {
      position: relative;
      display: inline-block;
      color: ${COLORS.white};
      z-index: 0;
    }
    strong::before {
      content: '';
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 18px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: ${COLORS.purple};
      z-index: -1;
      border-radius: 16px;
    }
  }
  @media (max-width: 1300px) {
    margin-bottom: 40px;
    h1,
    h2,
    p {
      ${TYPOGRAPHY.title1SemiBold64};
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 16px;
    h1,
    h2,
    p {
      ${TYPOGRAPHY.title3SemiBold32};
      strong::before {
        width: calc(100% + 14px);
        height: 100%;
        border-radius: 7px;
      }
    }
  }
`;

const Description = styled.p`
  font-size: 1.25vw;
  line-height: 2.22vw;
  color: ${COLORS.black1};
  @media (max-width: 1300px) {
    ${TYPOGRAPHY.body1Regular18};
  }
`;

export default Hero;
