import { Image } from '@/components/Image';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue, ProductData } from '@/types';
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Products } from '../Products';
import ReactMarkdown from 'react-markdown';

interface DetailCardProps extends HTMLAttributes<HTMLElement> {
  data: {
    id: number;
    userCasesTitle: string;
    userCasesDescription: string;
    challengesTitle: string;
    challengesDescription: string;
    productsTitle: string;
    detailType: string;
    image: ImageDataValue;
    tabletImage: ImageDataValue;
    mobileImage: ImageDataValue;
  };
  title: string;
  subtitle: string;
  products: ProductData[];
}

const DetailCard: FC<DetailCardProps> = ({
  data,
  products,
  title,
  subtitle,

  ...props
}) => {
  return (
    <article {...props}>
      <Head>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Head>
      <CardWrapper>
        <InformationBlock
          className={data.detailType}
          $countProducts={products.length}
        >
          <InformationImageWrap>
            <DesktopImage localFile={data.image?.localFile} alt={title} />
            <TabletImage localFile={data.tabletImage?.localFile} alt={title} />
            <MobileImage localFile={data.mobileImage?.localFile} alt={title} />
          </InformationImageWrap>
          <DescriptionWrapper>
            <DescriptionSection>
              <DescriptionTitle>{data.userCasesTitle}</DescriptionTitle>
              <DescriptionSectionContent>
                <ReactMarkdown>{data.userCasesDescription}</ReactMarkdown>
              </DescriptionSectionContent>
            </DescriptionSection>
            <DescriptionSection>
              <DescriptionTitle>{data.challengesTitle}</DescriptionTitle>
              <DescriptionSectionContent>
                <ReactMarkdown>{data.challengesDescription}</ReactMarkdown>
              </DescriptionSectionContent>
            </DescriptionSection>
          </DescriptionWrapper>
        </InformationBlock>
        {products.length > 0 && (
          <Products data={products} title={data.productsTitle} />
        )}
      </CardWrapper>
    </article>
  );
};

const Head = styled.div`
  margin-bottom: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 650px) {
    align-items: flex-start;
    text-align: left;
    margin-bottom: 32px;
  }
`;

const Title = styled.h3`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  margin-bottom: 20px;
  @media (max-width: 650px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    color: ${COLORS.black2};
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black1};
  max-width: 450px;
  @media (max-width: 650px) {
    max-width: none;
  }
`;

const CardWrapper = styled.div`
  padding: 40px;
  background-color: ${COLORS.white};
  box-shadow: 0px 5px 29px rgba(202, 202, 219, 0.37);
  border-radius: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 20px;
  }
`;

const InformationBlock = styled.div<{ $countProducts: number }>`
  margin-bottom: ${({ $countProducts }) => ($countProducts > 0 ? '70px' : '0')};
  position: relative;
  &.flex {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    margin-bottom: ${({ $countProducts }) =>
      $countProducts > 0 ? '32px' : '0'};
  }
`;

const InformationImageWrap = styled.div`
  border-radius: 30px;
  overflow: hidden;
  .float & {
    position: absolute;
    right: 0;
    top: 0;
  }
  .flex & {
    margin-right: 70px;
  }
  .none & {
    margin-bottom: 60px;
  }
  @media (max-width: 1350px) {
    .float & {
      max-width: 600px;
    }
  }
  @media (max-width: 1150px) {
    .float & {
      max-width: 500px;
    }
    .flex & {
      margin-right: 40px;
    }
  }

  @media (max-width: 1024px) {
    .float &,
    .flex &,
    .none & {
      position: static;
      max-width: none;
      border-radius: 0;
      margin-right: 0;
      margin-bottom: 60px;
    }
  }
  @media (max-width: 768px) {
    .float &,
    .flex &,
    .none & {
      margin-bottom: 32px;
    }
  }
  @media (max-width: 450px) {
    align-self: center;
  }
`;

const DesktopImage = styled(Image)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TabletImage = styled(Image)`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const MobileImage = styled(Image)`
  display: none;
  @media (max-width: 450px) {
    display: block;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  .flex &,
  .float & {
    flex-direction: column;
  }
  .float & {
    padding-top: 30px;
  }
  @media (max-width: 1024px) {
    .flex &,
    .float & {
      flex-direction: row;
      padding-top: 0;
    }
  }
  @media (max-width: 768px) {
    .flex &,
    .float &,
    .none & {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const DescriptionSection = styled.div`
  position: relative;
  ::before {
    content: '';
    position: absolute;
    background-color: ${COLORS.lightPurple2};
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  :last-child::before {
    content: none;
  }
  .flex & {
    margin-bottom: 70px;
    padding-bottom: 90px;
    :last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .float & {
    margin-bottom: 60px;
    padding-bottom: 90px;
    max-width: 350px;
    :last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      max-width: none;
    }
    ::before {
      max-width: 290px;
    }
  }

  .none & {
    padding-right: 100px;
    margin-right: 100px;
    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
    ::before {
      width: 1px;
      height: 100%;
      left: auto;
      right: 0;
    }
  }

  @media (max-width: 1350px) {
    .flex & {
      margin-bottom: 50px;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 1024px) {
    .flex &,
    .float &,
    .none & {
      padding-right: 50px;
      margin-right: 50px;
      :last-child {
        padding-right: 0;
        margin-right: 0;
      }
      ::before {
        width: 1px;
        height: 100%;
        left: auto;
        right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    .flex &,
    .float &,
    .none & {
      padding-right: 0;
      margin-right: 0;
      padding-bottom: 32px;
      margin-bottom: 32px;
      max-width: none;
      :last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      ::before {
        width: 100%;
        height: 1px;
        top: auto;
        bottom: 0;
        right: 0;
        max-width: none;
      }
    }
  }
`;

const DescriptionTitle = styled.p`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black1};
  margin-bottom: 35px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineMedium22};
    color: ${COLORS.black2};
    margin-bottom: 28px;
  }
`;

const DescriptionSectionContent = styled.div`
  li {
    ${TYPOGRAPHY.body1Regular18};
    color: ${COLORS.black1};
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    :last-child {
      margin-bottom: 0;
    }
    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 3px;
      background-color: ${COLORS.purple};
    }
  }
`;

export default DetailCard;
