import { StyledTextArea } from '@/ui/components/StyledTextArea';
import { TextArea } from '@/ui/components/TextArea';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

interface TextFieldProps
  extends FieldRenderProps<string, HTMLTextAreaElement> {}

const TextField: FC<TextFieldProps> = ({ input, meta, ...rest }) => {
  const isError = meta.touched && meta.error;

  return <StyledTextArea {...input} error={isError} {...rest} />;
};

export default TextField;
