import React, { FC, useState } from 'react';
import { ProductData } from '@/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from '@/components/Image';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import SwiperCore, { Navigation } from 'swiper';
import { CustomLink } from '@/components/CustomLink';
import { Arrow } from '@/ui/iconComponents/Arrow';
import { useSwiperRef } from '@/hooks/useSwiperRef';

interface ProductsProps {
  data: ProductData[];
  title: string;
}

interface CustomSwiperProps extends SwiperCore {
  isLocked: boolean;
}

const Products: FC<ProductsProps> = ({ data, title }) => {
  const [prevEl, prevBtnRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextBtnRef] = useSwiperRef<HTMLButtonElement>();

  const [isStartSlider, setIsStartSlider] = useState(true);
  const [isEndSlider, setIsEndSlider] = useState(true);
  const sliderWrapperClassNames = `${isEndSlider ? 'end' : ''} ${
    isStartSlider ? 'start' : ''
  }`;

  function detectStartEndSlider(swiper: CustomSwiperProps) {
    setIsEndSlider(swiper.isEnd);
    setIsStartSlider(swiper.isBeginning);
  }

  return (
    <div>
      <Title>{title}</Title>
      <SliderWrapper className={sliderWrapperClassNames}>
        <>
          <PrevButton ref={prevBtnRef}>
            <Arrow />
          </PrevButton>
          <NextButton ref={nextBtnRef}>
            <Arrow />
          </NextButton>
        </>

        <Swiper
          modules={[Navigation]}
          spaceBetween={25}
          slidesPerView={'auto'}
          navigation={{
            prevEl,
            nextEl,
          }}
          onSlideChange={detectStartEndSlider}
          onSwiper={detectStartEndSlider}
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ProductWrapper>
                  {item.mainImage && (
                    <Image localFile={item.mainImage?.localFile} alt="ebee" />
                  )}
                  <ProductLink to={`/product/${item.id}`}>
                    {<ProductName>{item.title}</ProductName>}
                  </ProductLink>
                </ProductWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderWrapper>
    </div>
  );
};

const Title = styled.p`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black1};
  margin-bottom: 35px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineMedium22};
    color: ${COLORS.black2};
    margin-bottom: 28px;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  ::before,
  ::after {
    content: '';
    width: 25%;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    top: 0;
    height: 100%;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  ::before {
    left: 0;
    transform: rotate(180deg);
  }
  ::after {
    right: 0;
  }
  &.end::after {
    content: none;
  }
  &.start::before {
    content: none;
  }
  .swiper-slide {
    width: 210px;
  }

  @media (max-width: 768px) {
    ::before,
    ::after {
      content: none;
    }
  }
`;

const ProductLink = styled(CustomLink)`
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(47, 40, 138, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  opacity: 0;
  transition: 0.25s;
  :focus {
    opacity: 1;
  }
`;

const ProductName = styled.p`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.white};
  text-align: center;
`;

const ProductWrapper = styled.div`
  min-height: 215px;

  max-height: 215px;
  position: relative;
  border-radius: 10px;
  background-color: ${COLORS.lightGray2};
  overflow: hidden;
  z-index: 0;

  .gatsby-image-wrapper,
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    max-width: none;
    height: 100%;
    width: 100%;
  }
  :hover {
    ${ProductLink} {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    :hover {
      ${ProductLink} {
        opacity: 0;
      }
    }
  }
`;

const SliderNavBtn = styled.button`
  position: absolute;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.lightGray4};
  border-radius: 16px;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  :disabled {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const NextButton = styled(SliderNavBtn)`
  right: -20px;
`;

const PrevButton = styled(SliderNavBtn)`
  transform: translateY(-50%) rotate(180deg);
  left: -20px;
`;

export default Products;
