import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, TextareaHTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | boolean;
}

const TextArea: FC<TextAreaProps> = ({
  children,
  disabled,
  error,
  id,

  ...outerProps
}) => {
  return (
    <TextAreaRoot
      id={id}
      disabled={disabled}
      readOnly={disabled}
      {...outerProps}
    >
      {children}
    </TextAreaRoot>
  );
};

const TextAreaRoot = styled.textarea`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  color: ${COLORS.black2};
  background: $white;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 12px;
  outline: none;
  resize: none;
  min-height: 120px;

  ${TYPOGRAPHY.body2Light16};

  :focus {
    border: 1px solid ${COLORS.purple};
    caret-color: ${COLORS.purple};
  }

  :disabled {
    color: ${COLORS.lightGray6};
    border-color: ${COLORS.lightGray5};
  }

  ::placeholder {
    color: ${COLORS.grayDarker2};
  }

  .error &,
  &.error {
    border-color: ${COLORS.red};
  }
`;

export default TextArea;
