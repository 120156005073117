import React, { FC } from 'react';
import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface StageData {
  id: number;
  title: string;
  description: string;
}

interface StagesProps {
  stages: StageData[];
}

const Stages: FC<StagesProps> = ({ stages }) => {
  return (
    <section>
      <StyledContainer>
        <StepList>
          {stages.map((item) => (
            <StepItem key={item.id}>
              <StageTitle>{item.title}</StageTitle>
              <Description>
                <ReactMarkdown>{item.description}</ReactMarkdown>
              </Description>
              <ArrowElem />
            </StepItem>
          ))}
        </StepList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 90px;

  @media (max-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 90px;
  }
`;

const StepList = styled.ul`
  counter-reset: stages-counter;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 23px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    display: block;
  }
`;

const ArrowElem = styled.div`
  position: absolute;
  width: 38px;
  height: 104px;
  right: -38px;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 1;
  @media (max-width: 700px) {
    width: 104px;
    height: 41px;
    right: auto;
    top: auto;

    left: 50%;
    bottom: -41px;
    transform: translateX(-50%);
  }
`;

const StepItem = styled.li`
  padding: 50px 30px 30px;
  border: 2px solid ${COLORS.purple};
  border-radius: 10px;
  position: relative;

  ${ArrowElem} {
    background-image: url('/images/install-purple-arrow.svg');
  }

  ::before,
  ::after {
    position: absolute;
  }
  ::before {
    content: '';
    width: 49px;
    height: 49px;
    right: -2px;
    top: -2px;
    background-color: ${COLORS.purple};
    border-bottom-left-radius: 16px;
    border-top-right-radius: 10px;
  }
  ::after {
    counter-increment: stages-counter;
    content: counter(stages-counter);
    font-weight: 500;
    font-size: 31px;
    line-height: 39px;
    right: 13px;
    top: 3px;
    color: ${COLORS.white};
  }

  :nth-child(even) {
    border-color: ${COLORS.orange};
    ${ArrowElem} {
      background-image: url('/images/install-orange-arrow.svg');
    }
    li::before {
      background-color: ${COLORS.orange};
    }
    ::before {
      background-color: ${COLORS.orange};
    }
  }
  :nth-child(3n) {
    ${ArrowElem} {
      background-image: none;
    }
  }

  @media (max-width: 1300px) {
    padding-left: 30px;
  }

  @media (max-width: 1024px) {
    :nth-child(n) {
      ${ArrowElem} {
        background-image: url('/images/install-purple-arrow.svg');
      }
    }
    :nth-child(even) {
      ${ArrowElem} {
        background-image: url('/images/install-orange-arrow.svg');
      }
    }
    :nth-child(2n) {
      ${ArrowElem} {
        background-image: none;
      }
    }
  }
  @media (max-width: 700px) {
    padding: 40px 30px;
    padding-right: 30px;
    margin-bottom: 34px;
    border-radius: 16px;
    ::before {
      border-top-right-radius: 16px;
    }
    :nth-child(odd) {
      ${ArrowElem} {
        background-image: url('/images/install-purple-arrow-mobile.svg');
      }
    }
    :nth-child(even) {
      ${ArrowElem} {
        background-image: url('/images/install-orange-arrow-mobile.svg');
      }
    }
    :last-child {
      margin-bottom: 0;
      ${ArrowElem} {
        background-image: none;
      }
    }
  }
`;

const StageTitle = styled.p`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black1};
  margin-bottom: 20px;
`;

const Description = styled.div`
  li {
    ${TYPOGRAPHY.body2Light16};
    color: ${COLORS.black1};
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    ::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      background-color: ${COLORS.purple};
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default Stages;
